import moment from 'moment';
import normalize from './Normalizer'

export default class Query {
    query = {}

    addQueryTerm = ({ fieldName, config }) => {
        if (!this.query[fieldName]) {
            this.query[fieldName] = [];
        }
        this.query[fieldName].push(config);
    }

    contains = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                type: 'contains'
            }
        });
    }

    containswildcard = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                type: 'containswildcard'
            }
        });
    }

    exact_match_terms = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                type: 'exact_match_terms'
            }
        });
    }

    matchText = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                fuzzy: true,
                type: 'text'
            }
        });
    }

    matchAllWords = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                fuzzy: true,
                type: 'text'
            }
        });
    }
    matchTextExact = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                type: 'text',
                exact: true
            }
        });
    }

    matchSubstring = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: value,
                type: 'containssubstring'
            }
        });
    }

    matchNumber = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value,
                type: 'number'
            }
        });
    }

    match = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value: normalize(value),
                type: 'common'
            }
        });
    }

    notnull = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value,
                type: 'contains'
            }
        });
    }

    isNull = ({ fieldName }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                type: 'is_null'
            }
        });
    }
    isNotNull = ({ fieldName }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                type: 'is_not_null'
            }
        });
    }

    matchSubobject = ({ fieldName, id }) => {
        const key = `${fieldName}.objectid`;
        if (!this.query[key]) {
            this.query[key] = [];
        }
        this.query[key].push({
            value: id,
            type: 'objectid'
        })
    }

    matchSubobjectList = ({ fieldName, ids }) => {
        ids.forEach(id => {
            this.matchSubobject({
                fieldName,
                id
            })
        })
    }

    matchRange = ({ fieldName, lte, gte }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                lte, gte,
                type: 'range'
            }
        });
    }

    matchDate = ({ fieldName, value }) => {
        this.addQueryTerm({
            fieldName,
            config: {
                value,
                type: 'common'
            }
        });
    }

    matchDateAround = ({ fieldName, value, nbDays = 10, format = 'YYYY-MM-DD' }) => {
        const momentDate = moment(value, format);
        const gte = momentDate.clone().subtract(nbDays, 'days').format(format);
        const lte = momentDate.clone().add(nbDays, 'days').format(format);

        this.matchRange({
            fieldName,
            lte,
            gte
        })
    }

    matchNumberBetween = ({ fieldName, value_min, value_max }) => {

        const gte = value_min;
        const lte = value_max;

        this.matchRange({
            fieldName,
            lte,
            gte
        })
    }

    matchDateBetween = ({ fieldName, date_min, date_max, format = 'YYYY-MM-DD' }) => {
        const gte = moment(date_min, format);
        const lte = moment(date_max, format).add(1, 'days').format(format);;

        this.matchRange({
            fieldName,
            lte,
            gte
        })
    }


}