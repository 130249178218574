
import TYPES from 'Assets/json/TypesOeuvre.json'

export const getStringSurfaceDiameter = data => {
    const surface = (Math.round(100*(Math.PI * Math.pow((data.diametre || 0)/2, 2)))/1000000).toFixed(2)
    return surface + " m2"
}

export const getStringSurfaceRect = data => {
    const surface = (data.largeur * data.hauteur / 10000).toFixed(2)
    return surface + " m2"
}

export const getSurfaceDiameter = data => {
    return Math.round(100*(Math.PI * Math.pow((data.diametre || 0)/2, 2)).toFixed(2))
}

export const getSurfaceRect = data => {
    return Math.round(((data.largeur * data.hauteur / 10000).toFixed(2))*10000)
}

export const isOeuvreMultiple = (oeuvre) => {
    return oeuvre.type === 2 || oeuvre.type === 6 || oeuvre.type === 12 || oeuvre.type === 16 || oeuvre.type === 22 || oeuvre.type === 18;
}


export const typeOeuvreString = (type) => {

    var foundType = ""

    TYPES.forEach( eachType => {
        if (eachType["value"] === type) {
            foundType = eachType["loc"]["fr"]
        }
    })

    return foundType
}

 export const photoItemsWithType = (photo_items, type) => {

    var foundPhotos = []

    if ( photo_items ) {    
       photo_items.forEach(photo => {

            if ( photo && photo.type === type ) {
                 foundPhotos.push(photo)
            }
        })
    }
    return foundPhotos
}


 export const photoItemWithType = (photo_items, type) => {

    var foundPhoto = null

    if ( photo_items ) {    
       photo_items.forEach(photo => {

            if ( photo ) {
                if ( (photo.type === type) && !foundPhoto ) {
                    foundPhoto = photo
             }
            return foundPhoto
            }
        })
    }
    return foundPhoto
}

export const sortedPhotoItems = (photo_items, withBack) => {

    var newPhotoItems = []

    const frontPhoto = photoItemWithType(photo_items, "1")

    if ( frontPhoto !== null ) {
        newPhotoItems.push(frontPhoto)
    }

    if ( photo_items ) {
        photo_items.forEach(photo => {

            if ( photo && photo.type === "99" ) {
                newPhotoItems.push(photo)
            }
        })
    }

    if ( withBack === true ) {
    /*   const backPhoto = photoItemWithType(photo_items, "2")

        if ( backPhoto ) {
            newPhotoItems.push(backPhoto)
        }*/
        if ( photo_items ) {
            photo_items.forEach(photo => {

                if ( photo && photo.type === "2" ) {
                    newPhotoItems.push(photo)
                }
            })
        }
    }

    return newPhotoItems
}

export const oeuvreAuthentifiee = (data) => {

    const  remarques = data["remarques_authentification"]
    
    if ( (remarques === "Comité Soto") || (remarques === "Certificat Avila") ) {
        return 1
    }

    var found = 0;

    if ( data.authentifications ) {
        data.authentifications.forEach(authentification => {

            if ( authentification.status === "authentifie") {
                found = 1
            }
        })
    }

    return found
}

export const getLastPrice = ( data ) => {

    const price = data.encheres
    var lastPrice = null

    if ( price && price.length > 0) {
        price.sort(function (a, b) {
            if ( a && b ) {
                if ( a.date_enchere && b.date_enchere ) {
                    return a.date_enchere > b.date_enchere ? -1 : 1;
                } else if ( a.date_enchere ) {
                    return -1
                } else if ( b.date_enchere ) {
                    return 1
                }
            }
            return -1
        });
        lastPrice = price[0]

    }
    return lastPrice
}



