// @flow
import React, { useEffect, useState } from 'react';

import PageLayout from 'layouts/Helpers/PageLayout'

import { CircularProgress, Button } from '@material-ui/core'

import {
    Add as AddIcon,
    Edit as EditIcon
} from '@material-ui/icons'

import DataTable from 'components/DataTable'
import UserModal from 'pages/Avila/Modals/User'

import { getAllUsers } from 'Api';



export default () => {

    const [ users, setUsers ] = useState([]);
    const [ editUser, setEditUser ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    const loadData = async () => {
        const users = await getAllUsers()
      //  console.log("users", users);
        setUsers(users || []);
        setLoading(false);
    }
    useEffect(() => {
        loadData()
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (
        <PageLayout title={"Utilisateurs"} actions={(
            <div>
                <Button startIcon={<AddIcon />} variant='contained' onClick={setEditUser} style={{marginRight: '10px'}}>Ajouter</Button>
            </div>
        )}>

            {editUser &&
                <UserModal
                    data={editUser}
                    userid={editUser.id}
                    onClose={() => {
                        setEditUser(false)
                    }}
                    onSave={loadData}
                />
            }

            <DataTable
                data={users}

                config={{

                    cells: [
                        { title: "Nom", key: 'last_name' },
                        { title: "Prénom", key: 'first_name' },
                        { title: "Email", key: 'email' },
                        { title: "Profil de permissions", key: 'permission_profile' },
                        { title: "Accès autorisé", key: 'activ' }
                    ],
                    actions: [
                        { icon: <EditIcon />, title: "Edit", onClick: (id, user) =>  setEditUser(user)}
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === 'activ') {
                        return item.activ ? "Oui" : "Non";
                    }

                     return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />

        </PageLayout>

    )
}
